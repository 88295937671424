//
// Name:            Header
//
// Description:     Contains all header styles
//

// ========================================================================
// Component: Header
// ========================================================================

header {
  position: relative;
  width: 100%;
  height: 170px;
  background: $purple;
  z-index: 500;

  &.slate {
    height: 125px;
  }
  .utility {
    position: relative;
    z-index: 99;
    width: 100%;
    height: auto;
    background: $dark_purple;
    height: 50px;
    padding: 0;

    p {
      display: inline-block;
      width: auto;
      margin-top: 17px;
      color: #fff;
      font-family: $akkurat_reg;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      letter-spacing: 2px;
      padding-left: 35px;
      vertical-align: top;
    }
    nav {
      display: inline-block;
      width: auto;
      vertical-align: top;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          display: inline-block;
          width: auto;
          margin: 0 10px;

          a {
            color: #fff;
            font-family: $akkurat_bold;
            font-size: 14px;
            line-height: 50px;
            text-transform: uppercase;
            letter-spacing: 2px;
            text-decoration: none;

            &:hover, &:focus {
              color: #a18fbc;
            }
          }
        }
      }
    }
    .end-xs nav {
      float: right;
      margin-right: calc(120px + .5rem);
    }
    .donate {
      display: inline-block;
      position: absolute;
      right: .5rem;
      width: 120px;
      padding: 0;
      text-align: center;
      color: #fff;
      font-family: $akkurat_bold;
      font-size: 14px;
      line-height: 50px;
      text-transform: uppercase;
      letter-spacing: 2px;
      text-decoration: none;
      background: rgba(0, 0, 0, 0.5);

      &:hover, &:focus {
        color: #a18fbc;
      }
    }
  }
  .brand {
    position: relative;
    height: 120px;
    z-index: 99;
    background: $purple;

    .logo {
      display: block;
      position: absolute;
      width: 200px;
      height: 55px;
      background-size: 100%;
      top: 30px;
      left: 35px;
      text-align: left;
      text-indent: -9999px;
      background: url(../images/logo.svg) 50% no-repeat;
      background-size: 100%;
    }
    .search-btn {
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      width: 128px;
      height: 120px;
      border-left: 1px solid #3b1d68;
      margin: 0;
      background: url(../images/search-icon.svg) 50% no-repeat;

      &:hover,
      &:focus,
      &.open {
        background: url(../images/search-icon.svg) $dark-purple 50% no-repeat;
      }
    }
  }
  form#search {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 70px;
    margin: 0;
    z-index: 6;
    border-top: 1px solid #33185b;
    background: $dark-purple;
    transform: translate3d(0, 0, 0);
    transition: transform .4s cubic-bezier(.225, .46, .32, 1);

    &.open {
      transform: translate3d(0, 170px, 0) !important;
    }
    input[type="text"] {
      display: block;
      width: 100%;
      background: transparent;
      padding: 10px 22px;
      border: none;
      color: #fff;
      font-size: 18px;

      &::-webkit-input-placeholder {
        color: #fff;
        opacity: .5;
      }
      &::-moz-placeholder {
        color: #fff;
        opacity: .5;
      }
      &:-ms-input-placeholder {
        color: #fff;
        opacity: .5;
      }
      &:-moz-placeholder {
        color: #fff;
        opacity: .5;
      }
    }
    input[type="submit"] {
      display: none;
    }
  }
}
@media only screen and (max-width: 1220px) and (min-width: 1024px) {
  header {
    .utility {
      p {
        padding-left: 20px;
      }

      nav {
        ul {
          li {
            margin: 0 5px;

            a {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1080px) and (min-width: 1px) {
  header .utility .donate,
  header .utility nav ul li a,
  header .utility p {
    font-size: 12px;
  }
}
@media only screen and (max-width: 1023px) and (min-width: 1px) {
  header {
    height: 81px;

    .row {
      margin: 0;
    }
  }
  header .utility {
    display: none;
  }
  header .brand {
    height: 81px;
    border-bottom: 1px solid #28104c;
  }
  header .hamburger {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 80px;
    height: 80px;
    border-left: 1px solid $dark-purple;

    &:hover, &:focus {
      opacity: 1;
    }
  }
  header .brand .search-btn {
    right: 80px;
    width: 80px;
    height: 80px;
  }
  .hamburger-box {
    width: 34px;
    height: 28px;
  }
  .hamburger-inner,
  .hamburger-inner:after,
  .hamburger-inner:before {
    background: #fff;
    border-radius: 0;
    height: 3px;
    width: 34px;
  }
  .hamburger-inner:before {
    top: -15px;
  }
  .hamburger-inner:after {
    bottom: -15px;
  }
  header .brand .logo {
    top: 13px;
  }
  header form#search.open {
    transform: translate3d(0, 80px, 0) !important;
  }
}
@media only screen and (max-width: 768px) and (min-width: 1px) {
  header {
    height: 71px;
  }
  header .brand {
    height: 71px;
  }
  header .brand .search-btn {
    right: 70px;
    width: 70px;
    height: 71px;
  }
  header .hamburger {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 70px;
    height: 70px;
    border-left: 1px solid $dark-purple;

    &:hover, &:focus {
      opacity: 1;
    }
  }
  header form#search.open {
    transform: translate3d(0, 70px, 0) !important;
  }
  header .brand .logo {
    top: 17px;
    left: 20px;
    width: 113px;
    height: 31px;
  }
}
.nav-overview .col-xs-4 .mainadmissionutility {
  margin-top: 36px;
}